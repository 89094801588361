import { Fragment, useContext, useRef } from "react";

import logo from "assets/quahd/quahd.png";
import { PAGES } from "router";
import { HeaderNav, HeaderWrapper, Logo } from "./index.ui";
import MenuDrawer from "./MenuDrawer";
import Menu from "./Menu";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import { ChangePasswordDialog } from "module/ChangePassword";
import { UpdateUserDialog } from "module/UpdateUser";
import { PageContext } from "context/page";

export default (props) => {
  const { goto } = useContext(PageContext);

  const menuDrawer = useRef();
  const changePasswordDialogRef = useRef();
  const updateUserDialogRef = useRef();

  const PAGE_ITEMS = {
    CATEGORY: [
      // {
      //   name: "Trang chủ",
      //   path: PAGES.COURSE,
      // },
      {
        name: "Khóa học",
        path: PAGES.COURSE,
        selected: true,
      },
    ],
  };

  const openUserDialog = () => {
    updateUserDialogRef.current.open();
  };

  const openPasswordDialog = () => {
    changePasswordDialogRef.current.open();
  };

  return (
    <Fragment>
      <ChangePasswordDialog ref={changePasswordDialogRef} />
      <UpdateUserDialog ref={updateUserDialogRef} />

      <div style={{ height: "5rem" }} />

      <HeaderWrapper>
        <Logo className="pointer" onClick={() => goto(PAGES.DEFAULT)}>
          <img src={logo} style={{ width: "8rem" }} />
        </Logo>

        <HeaderNav className="mobile">
          <FormatListBulletedIcon
            className="pointer"
            onClick={() => menuDrawer.current.open()}
          />
          <MenuDrawer
            ref={menuDrawer}
            items={PAGE_ITEMS}
            openUserDialog={openUserDialog}
            openPasswordDialog={openPasswordDialog}
          />
        </HeaderNav>

        <HeaderNav className="pc">
          <Menu
            items={PAGE_ITEMS}
            openUserDialog={openUserDialog}
            openPasswordDialog={openPasswordDialog}
          />
        </HeaderNav>
      </HeaderWrapper>
    </Fragment>
  );
};

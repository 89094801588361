import { enqueueSnackbar } from "notistack";
import { COURSE } from "./constants";

export const validateMail = (email) => {
  return /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(email);
};

export const validatePassword = (pw) => {
  return /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/g.test(pw);
};

export const noti = (text, variant = "success") => {
  enqueueSnackbar(text, {
    variant,
    anchorOrigin: { vertical: "bottom", horizontal: "right" },
    autoHideDuration: 3000,
  });
};

export const e500 = () => {
  noti("Lỗi không xác định", "error");
};

export const toParams = (params) => {
  return (
    "?" +
    Object.keys(params)
      .map((key) => key + "=" + params[key])
      .join("&")
  );
};

export const toFormData = (data) => {
  const formData = new FormData();
  Object.keys(data).forEach((key) => formData.append(key, data[key]));
  return formData;
};

export const onResult = (result) => {
  return result?.statusCode === 200;
};

export const formatText = (type, string) => {
  if (!string) return "";

  switch (type) {
    case "vnd":
      return `${+string}`.replace(/(.)(?=(\d{3})+$)/g, "$1.");
  }

  return string;
};

export const getLessonByType = (data) => {
  if (!data) return [[], []];

  const left = [];
  const right = [];

  data.forEach(({ type, name }) => {
    const index = name?.indexOf(":");

    let item;
    if (index === -1) item = ["", name.trim()];
    else item = [name.slice(0, index), name.substr(index + 1)];

    switch (type) {
      case COURSE.TYPE.BASIC:
        left.push(item);
        break;

      case COURSE.TYPE.ADVANCE:
        right.push(item);
        break;
    }
  });

  return [left, right];
};

export const getLessonByTypeFull = (data) => {
  if (!data) return [[], []];

  const left = [];
  const right = [];

  data.forEach((lesson) => {
    const { type, name } = lesson;
    const index = name?.indexOf(":");

    let item;
    if (index === -1) item = ["", name.trim()];
    else item = [name.slice(0, index), name.substr(index + 1)];

    switch (type) {
      case COURSE.TYPE.BASIC:
        left.push({ ...lesson, customName: item });
        break;

      case COURSE.TYPE.ADVANCE:
        right.push({ ...lesson, customName: item });
        break;
    }
  });
  return [left, right];
};

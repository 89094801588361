import { createContext, useEffect, useMemo, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";

export const LoadingContext = createContext();

export const LoadingProvider = ({ children }) => {
  const [loading, setLoading] = useState({});

  // create an event listener
  useEffect(() => {});

  const isLoading = useMemo(() => {
    return !!Object.keys(loading).filter((key) => loading[key]).length;
  }, [loading]);

  const onLoading = (name) => {
    setLoading((e) => ({ ...e, [name]: true }));
  };

  const onFinish = (name) => {
    setTimeout(() => setLoading((e) => ({ ...e, [name]: false })), 1000);
  };

  return (
    <LoadingContext.Provider value={{ isLoading, onLoading, onFinish }}>
      <div>
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            zIndex: 9999,
            backgroundColor: "rgba(0,0,0,0.5)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            visibility: isLoading ? "visible" : "hidden",
          }}
        >
          <CircularProgress />
        </div>
        {children}
      </div>
    </LoadingContext.Provider>
  );
};

import { Stack } from "@mui/material";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import styled from "styled-components";

export const Logo = styled.div`
  position: absolute;
  margin-top: 0.5rem;
  margin-left: 2rem;
  position: fixed;
  z-index: 1;
`;

export const HeaderWrapper = styled.div`
  position: absolute;
  z-index: 1;
`;

export const HeaderNav = styled(Stack).attrs({
  flexDirection: "row-reverse",
})`
  width: 100%;
  height: 5rem;
  padding-left: 12rem;
  padding-right: 1rem;
  align-items: center;
  position: fixed;
  background-color: #1c1c1c;
  justify-content: space-between;
  color: var(--white);
  border: 1px solid #313131;
`;

export const BulletedIcon = styled(FormatListBulletedIcon).attrs({
  className: "pointer",
})`
  @media screen and (min-width: 1000px) {
    display: none !important;
  }
`;

import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { clearAll, getItem, removeItem, setItem } from "../utils/localStorage";
import { authGetProfile, authLogout, authRefresh } from "api/auth";
import { PageContext } from "./page";
import { PAGES } from "router";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { CLIENT_ID } from "utils/constants";
import { LoginDialog } from "module/Login";
import { RegisterDialog } from "module/Register";
import { getUser } from "api/user";
import { LoadingContext } from "./loading";
import { onResult } from "utils/helper";
// import { getUser } from "api/user";

export const ACCESS_TOKEN = "access_token";
export const REFRESH_TOKEN = "refresh_token";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const { goto } = useContext(PageContext);
  const { onLoading, onFinish } = useContext(LoadingContext);

  const [isLogin, setIsLogin] = useState();
  const [user, setUser] = useState({});

  const loginRef = useRef();
  const registerRef = useRef();

  const onLogin = () => loginRef.current.open();
  const onRegister = () => registerRef.current.open();

  useEffect(() => {
    const token = getItem(ACCESS_TOKEN);
    if (token) {
      setIsLogin(true);
      getUserData();
    }
  }, []);

  const onLogout = () => {
    setIsLogin(false);
    authLogout();
    removeItem(ACCESS_TOKEN);
    removeItem(REFRESH_TOKEN);
    goto(PAGES.DEFAULT);
  };

  const onLoginSuccess = (data) => {
    clearAll();
    Object.keys(data).forEach((k) => {
      setItem(k, data[k]);
    });
    setIsLogin(true);
    getUserData();
  };

  const api = async (func, ...rest) => {
    const result = await func(...rest);
    if (result?.status === 401) {
      const refesh = await authRefresh();

      if (refesh?.access_token) {
        onLoginSuccess(refesh);
        return await api(func, ...rest);
      } else {
        onLogout();
      }
    }

    return result;
  };

  const getUserData = async () => {
    onLoading("AuthProvider");
    const result = await api(authGetProfile);
    if (onResult(result)) {
      setUser(result.data);
    }
    onFinish("AuthProvider");
  };

  return (
    <GoogleOAuthProvider clientId={CLIENT_ID}>
      <AuthContext.Provider
        value={{
          user,
          isLogin,
          api,
          onLogout,
          onLoginSuccess,
          onLogin,
          onRegister,
          getUserData,
        }}
      >
        <LoginDialog ref={loginRef} />
        <RegisterDialog ref={registerRef} />
        {children}
      </AuthContext.Provider>
    </GoogleOAuthProvider>
  );
};
